import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay])

export default function sliders() {
	;(function ($) {
		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll('.video-swiper')
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,
					loop: true,
					//autoplay: {
					//delay: 5000,
					//disableOnInteraction: true,
					//},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					freeMode: true,
					centeredSlides: true,
					slidesPerView: 2,
					spaceBetween: 60,

					breakpoints: {
						640: {
							spaceBetween: 220,
						},
						992: {
							spaceBetween: 280,
						},
						1172: {
							spaceBetween: 380,
						},
					},
				}

				// init slider
				new Swiper(el, options)
			})
		})
	})(jQuery)

	// Smart Phone Gallery
	;(function ($) {
		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll(
				'.smartphone-gallery-swiper'
			)
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,
					loop: true,
					//autoplay: {
					//delay: 5000,
					//disableOnInteraction: true,
					//},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					freeMode: true,
					centeredSlides: true,
					slidesPerView: 5,
					spaceBetween: 32,
					slidesPerView: 'auto',

					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					},

					breakpoints: {
						640: {
							spaceBetween: 25,
							slidesPerView: 3,
						},
						992: {
							spaceBetween: 40,
							slidesPerView: 4,
						},
						1172: {
							spaceBetween: 32,
							slidesPerView: 3,
						},
					},
				}

				// init slider
				new Swiper(el, options)
			})
		})
	})(jQuery)

	// Simple Video Gallery
	;(function ($) {
		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll('.simple-video-swiper')
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,
					loop: true,
					//autoplay: {
					//delay: 5000,
					//disableOnInteraction: true,
					//},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					freeMode: true,
					centeredSlides: true,
					slidesPerView: 1,
					effect: 'fade',
				}

				// init slider
				new Swiper(el, options)
			})
		})
	})(jQuery)

	// Sub Testimonial
	;(function ($) {
		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll('.sub-testimonial')
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,
					loop: true,
					//autoplay: {
					//delay: 5000,
					//disableOnInteraction: true,
					//},
					allowTouchMove: false,
					spaceBetween: 150,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					freeMode: true,
					centeredSlides: true,
					slidesPerView: 1,
					effect: 'fade',
				}

				// init slider
				new Swiper(el, options)
			})
		})
	})(jQuery)

	// Testimonial
	;(function ($) {
		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll(
				'.testimonials-gallery-swiper'
			)
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,
					loop: true,
					//autoplay: {
					//delay: 5000,
					//disableOnInteraction: true,
					//},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					freeMode: true,
					centeredSlides: true,
					slidesPerView: 1,
					effect: 'fade',
				}

				// init slider
				new Swiper(el, options)
			})
		})
	})(jQuery)
}
