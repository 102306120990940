export default function popUp() {
	;(function ($) {
		// Idle Popup
		setTimeout(() => {
			if (!document.querySelector('.exit-activated')) {
				document
					.querySelector('#popup-idle')
					.classList.add('idle-activated')
				document.querySelector('#idle-btn').click()
			}
		}, 240000);

        $('.popup-section a[href="#anchor-point"]').click(function(){
        	$('[data-fancybox-close]', $(this).parent()).click();
        });

		// Exit Popup
		function onMouseOut(event) {
			if (
				event.clientY < 50 &&
				event.relatedTarget == null &&
				event.target.nodeName.toLowerCase() !== 'select'
			) {
				// Remove this event listener
				document.removeEventListener('mouseout', onMouseOut)

				// Show the popup
				if (!document.querySelector('.idle-activated')) {
					document
						.querySelector('#popup-exit')
						.classList.add('exit-activated')
					document.querySelector('#popup-exit-btn').click()
				} else {
					return
				}
			}
		}

		document.addEventListener('mouseout', onMouseOut)
	})(jQuery)
}
